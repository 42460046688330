import React, {useMemo} from "react";
import {Table} from "../basic/Table";
import {Card} from "@blueprintjs/core";
import {BigNumber} from "bignumber.js";
import {chain} from "lodash";
import {Percentage} from "../basic/Percentage";

export function DepotStatistics({groups}) {
    const calculated = useMemo(() => {
        return chain(groups)
            .filter(group => group.history.length > 0)
            .map(group => {
                let years = [];
                let beginningOfYear = group.history[0];
                let lastEntry = beginningOfYear;
                for (let h of group.history) {
                    let lastYear = lastEntry?.date.substring(0, 4);
                    let currentYear = h.date.substring(0, 4);
                    if (lastYear !== currentYear) {
                        years.push({
                            year: lastYear,
                            open: new BigNumber(beginningOfYear.close),
                            close: new BigNumber(lastEntry.close),
                        })
                        beginningOfYear = h;
                    }
                    lastEntry = h;
                }
                years.push({
                    year: lastEntry?.date.substring(0, 4),
                    open: new BigNumber(beginningOfYear.close),
                    close: new BigNumber(lastEntry.close),
                })


                const result = {
                    group: group.name,
                }

                for (let year of years) {
                    result[year.year] = year.close.minus(year.open).dividedBy(year.open);
                }

                return result;
            })
            .value();
    }, [groups])

    const currentYear = new Date().getFullYear()
    const columns = useMemo(() => [
        {
            Header: "Gruppe",
            accessor: "group",
            width: "20%",
        },
        ...Array.from({length: 8}, (x, i) => currentYear - i)
            .reverse()
            .map(year => (
                {
                    Header: `${year}`,
                    accessor: `${year}`,
                    Cell: Percentage,
                    width: "50px",
                }
            ))
    ], []);


    return (
        <Card style={{padding: 0}} elevation={1}>
            <Table
                data={calculated}
                updateSelectedRows={() => {
                }}
                columns={columns}
                style={{width: "100%"}}
            />
        </Card>
    );
}