import React, {Fragment} from "react";
import {useFetch} from "../fetch/useFetch";
import {Spinner} from "../basic/Spinner";
import {Card, HTMLTable} from "@blueprintjs/core";
import {DateTime} from "../basic/DateTime";
import {Money} from "../basic/Money";
import {BigNumber} from "bignumber.js";
import {useParams} from "react-router-dom";

export function TaxStatementPage() {
    const {year} = useParams()
    const {data, loading, reload} = useFetch(`/api/taxstatement/${year}`);

    if (loading) {
        return <Spinner/>
    }

    let stocks = Object.entries(data.accounts)
        .flatMap(([accountName, stocks]) => {
            return stocks;
        })

    let totalStart = stocks.map(stock => stock.price_start_of_year * stock.quantity_start_of_year).reduce((a, b) => a + b, 0)
    let totalGain = stocks
        .flatMap(stock => stock.transactions)
        .map(tx => tx.type === "Kauf" ? tx.quantity * tx.unitcost : -tx.quantity * tx.unitcost)
        .reduce((a, b) => a + b, 0)
    let totalEnd = stocks
        .map(stock => stock.price_end_of_year * stock.quantity_start_of_year +
            stock.transactions.map(tx => tx.type === "Kauf" ? tx.quantity * stock.price_end_of_year : -tx.quantity * stock.price_end_of_year).reduce((a, b) => a + b, 0))
        .reduce((a, b) => a + b, 0)

    return <>
        <h1>Vermögensvermehrung {year}</h1>
        <div style={{display: "inline-block"}}>
            <Card style={{padding: 0}} elevation={1}>
                <HTMLTable striped bordered compact>
                    <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Datum</th>
                        <th>Menge</th>
                        <th>Wert 01.01.</th>
                        <th>Einkauf</th>
                        <th>Wert 31.12.</th>
                        <th>Währung</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stocks
                        .map(stock => {
                            console.log(stock)
                            return <Fragment key={stock.symbol}>
                                <tr key={stock.symbol}>
                                    <td>{stock.symbol}</td>
                                    <td>01.01.{year}</td>
                                    <td><Money value={BigNumber(stock.quantity_start_of_year)}/></td>
                                    <td>
                                        <Money value={BigNumber(stock.price_start_of_year * stock.quantity_start_of_year)}/>
                                    </td>
                                    <td></td>
                                    <td>
                                        <Money value={BigNumber(stock.price_end_of_year * stock.quantity_start_of_year)}/>
                                    </td>
                                    <td>{stock.currency}</td>
                                </tr>
                                {stock.transactions.map((tx, i) => {
                                    return <tr key={i}>
                                        <td>{stock.symbol}</td>
                                        <td>{tx.date}</td>
                                        <td><Money value={BigNumber(tx.quantity)}/></td>
                                        <td></td>
                                        <td>
                                            <Money
                                                value={BigNumber(tx.type === "Kauf" ? tx.quantity * tx.unitcost : -tx.quantity * tx.unitcost)}
                                            />
                                        </td>
                                        <td>
                                            <Money
                                                value={BigNumber(tx.type === "Kauf" ? tx.quantity * stock.price_end_of_year : -tx.quantity * stock.price_end_of_year)}
                                            />
                                        </td>
                                        <td>{stock.currency}</td>
                                    </tr>
                                })}
                            </Fragment>
                        })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Money value={BigNumber(totalStart)}/></td>
                        <td><Money value={BigNumber(totalGain)}/></td>
                        <td><Money value={BigNumber(totalEnd)}/></td>
                    </tr>
                    </tfoot>
                </HTMLTable>
            </Card>
        </div>

        <h1>Steuerauszug {year}</h1>
        {Object.entries(data.accounts)
            .map(([accountName, stocks]) => {
                return <div key={accountName}>
                    <h2>{accountName}</h2>
                    {stocks.map(stock => {
                        return <div key={stock.symbol}>
                            <h3>{stock.symbol}</h3>
                            <p>Menge Anfangs Jahr: {stock.quantity_start_of_year}</p>
                            <p>Menge Ende Jahr: {stock.quantity_end_of_year}</p>
                            {stock.transactions.length > 0 &&
                                <div style={{display: "inline-block"}}>
                                    <Card style={{padding: 0}} elevation={1}>
                                        <HTMLTable striped bordered compact>
                                            <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Typ</th>
                                                <th>Menge</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {stock.transactions.map((tx, i) =>
                                                <tr key={i}>
                                                    <td><DateTime dateOnly value={tx.date}/></td>
                                                    <td>{tx.type}</td>
                                                    <td>{tx.quantity}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </HTMLTable>
                                    </Card>
                                </div>}
                        </div>
                    })}
                </div>
            })}
    </>
}